/* eslint-disable */

import Vue from 'vue';
import Router from 'vue-router'

Vue.use(Router)

const routes = [
	{
		path: '/',
		name: 'Root',
		redirect: '/login'
	},
	// ログイン
	{
		path: '/login',
		name: 'Login',
		component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
		// props: true,
	},	
	{
		path: '/common',
		name: 'Common',
		component: () => import(/* webpackChunkName: "common" */ '../views/common/Common.vue'),
		children: [
			{
				path: 'home',
				name: 'Home',
				component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
			},
			{
				path: 'goal',
				name: 'Goal',
				component: () => import(/* webpackChunkName: "goal" */ '../views/Goal.vue')
			},
			{
				path: 'weight',
				name: 'Weight',
				component: () => import(/* webpackChunkName: "weight" */ '../views/Weight.vue')
			},
			{
				path: 'gpt',
				name: 'Gpt',
				component: () => import(/* webpackChunkName: "weight" */ '../views/Gpt.vue')
			},
		]
	},
	// それ以外のURLは強制的にログイン画面へ遷移させる
	{
		path: '*',
		redirect: { name : 'Login' },
	}
]

const router = new Router({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
})

export default router

// addEventListener("popstate", () => {
// 	if (router.currentRoute === '/login') {
// 		router.go({path: router.currentRoute.path, force: true})
// 	}
// 	else{
// 		router.push({name: 'Login'})
// 	}
// })