import Vue from 'vue';
import App from './App.vue';
import router from './router/index.js';
import store from './store';
import vuetify from './plugins/vuetify';
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.config.productionTip = false;

Vue.use(VueAxios, axios)

import Mixin from '@/components/mixin/common.js'
Vue.mixin(Mixin)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
