import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		loginInfo: {
			userId: '',
			userName: '',
			age: 0,
			height: 170,
			weight: 0,
			goal: "2000",
		},
		loading: false,
		dialog: {
			title: '',
			message: '',
		},
	},
	mutations: {
		setLoginInfo(state, payload) {
			state.loginInfo.userId = payload.user_id
			state.loginInfo.userName = payload.user_name
			state.loginInfo.age = payload.age
			state.loginInfo.height = payload.height
			state.loginInfo.weight = payload.weight
		},
		setGoal(state, payload) {
			state.loginInfo.goal = payload.goal;
		},
		setWeight(state, payload) {
			state.loginInfo.weight = payload.weight;
		},
        nowLoading(state, payload) {
            state.loading = payload
        },
		setDialog(state, payload) {
			state.dialog.title = payload.title
			state.dialog.message = payload.message
		},
		clearDialog(state) {
			state.dialog.title = ''
			state.dialog.message = ''
		},
	},
	actions: {
        setLoginInfo(store, payload) {
            store.commit('setLoginInfo', payload)
        },
		setGoal(store, payload) {
			store.commit('setGoal', payload);
		},
		setWeight(store, payload) {
			store.commit('setWeight', payload);
		},
        nowLoading(store, payload) {
            store.commit('nowLoading', payload)
        },
        setDialog(store, payload) {
            store.commit('setDialog', payload)
        },
	},
	getters: {
		loginInfo: state => state.loginInfo,
        getLoading: state => state.loading,
		dialog: state => state.dialog,
	}
})
