<template>
<!-- eslint-disable -->
	<v-dialog
		v-model="show"
		persistent
		max-width="90%"
		style="z-index: 999;"
		>
		<v-card class="gradation">
			<div class="text-center pt-6 pb-4 pl-2" style="white-space: pre-wrap;">
				<h3 class="pb-2">{{ this.title }}</h3>
				{{ this.message }}
			</div>
			<v-card-actions>
				<v-btn block tile color="red" dark depressed small @click="close()" class="mb-2">
					閉じる
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>

</template>

<script>
/*eslint-disable */
export default {
    name: 'Dialog',
    data() {
        return {
            show: false,
        }
    },
    computed: {
        dialog() {
            return this.$store.getters.dialog
        },
        title() {
            return this.dialog.title
        },
        message() {
            return this.dialog.message
        },
    },
    methods: {
        close() {
            this.show = !this.show
            this.$store.commit('clearDialog')
			this.$store.dispatch('setPage', '')
			this.$router.push({ name: 'Home' })
        },
    },
    created() {
        if (!this.title) {
            this.title = "お知らせ"
        }
    },
	watch: {
        message(newVal) {
            this.show = newVal != ''
        }
	}

}
</script>